@use '../../modules/common/utils/styles/mixins';
@import '../styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background-color: $main-bg;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  padding-bottom: 70px;
}

.logo {
  width: 68px;
  height: 68px;
  margin-top: 20px;
  margin-bottom: 37px;

  @include mixins.from-tablet {
    width: 127px;
    height: 127px;
    margin-top: 62px;
    margin-bottom: 62px;
  }
}

.link {
  margin-bottom: 10px;
  color: $text-color;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -0.02rem;

  @include mixins.from-tablet {
    font-size: 56px;
  }

  &:hover {
    color: $link-color-hover;
  }

  &Inner {
    text-align: center;
    font-size: 16px;
    line-height: 32px;
    text-transform: unset;

    @include mixins.from-tablet {
      font-size: 24px!important;
      line-height: 42px;
    }
  }
}

.accordionHeader {
  // margin-left: 34px;
}

.accordionBody {
  text-align: center;
}
