@use '../../utils/styles/mixins';
@import '../../../../static/styles/variables.scss';

.formWrapper {
  background-color: $menu-bg;
  border-radius: 10px;
  padding: 45px 16px 55px;
}

.formTitle {
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  font-weight: 700;

  @include mixins.from-tablet {
    max-width: 475px;
    margin: 0 auto 36px;
    font-size: 26px;
    line-height: 31px;
  }
}

.formBody {
  @include mixins.from-tablet {
    width: 300px;
    margin: 0 auto;
  }

  @include mixins.from-desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 950px;
  }
}

.input {
  width: 100%;
  margin-bottom: 20px;

  @include mixins.from-tablet {
    max-width: 300px;
    margin-bottom: 25px;
  }

  @include mixins.from-desktop {
    margin-bottom: 0;
  }
}

.buttonWrapper {
  width: 100%;

  @include mixins.from-tablet {
    max-width: 300px;
  }
}

.modalClose {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 20px;
  right: 20px;
  transition: all 0.1s linear;

  @include mixins.from-tablet {
    width: 20px;
    height: 20px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
}

.modalContent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalImage {
  width: 102px;
  height: 102px;
  margin: 60px auto 25px;

  @include mixins.from-tablet {
    width: 120px;
    height: 120px;
  }

  @include mixins.from-desktop {
    width: 147px;
    height: 147px;
  }
}

.modalTitle {
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

  @include mixins.from-tablet {
    font-size: 30px;
    line-height: 36px;
  }
}

.modalDescription {
  margin-bottom: 50px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;

  @include mixins.from-tablet {
    margin-bottom: 75px;
    max-width: 490px;
    font-size: 16px;
    line-height: 20px;
  }

  @include mixins.from-desktop {
    margin-bottom: 34px;
  }
}

.modalBtn {
  width: 205px;
  height: 47px;
  background: $link-color-active;
  border-radius: 40px;
  margin-bottom: 70px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0.02em;
  text-align: center;
  transition: background 0.1s linear;

  @include mixins.from-tablet {
    height: 56px;;
    font-size: 16px;
    line-height: 56px;
  }

  &:hover {
    background-color: $secondary;
    cursor: pointer;
  }
}
