@use '../../utils/styles/mixins';
@import '../../../../static/styles/variables.scss';

.wrapper {
  width: 100%;
  position: relative;
}

.wrapperWithMsg {
  &::after {
    content: "Please, fill the field";
    display: block;
    position: absolute;
    bottom: -30px;
    left: 26px;
    font-weight: 400;
    font-size: 14px;
    color: $error-text;
  }
}

.wrapperWithMsgWrongNumber {
  &::after {
    content: "Wrong number";
    display: block;
    position: absolute;
    bottom: -30px;
    left: 26px;
    font-weight: 400;
    font-size: 14px;
    color: $error-text;
  }
}

.input {
  width: 100%;
  height: 50px;
  border-radius: 40px;
  background-color: $input-bg;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: $text-color;
  border: 0;
  text-indent: 24px;
  caret-color: $link-color-active;

  @include mixins.from-tablet {
    font-size: 16px;
  }

  @include mixins.from-desktop {
    width: 300px;
  }

  &::placeholder {
    color: $gray-color;
  }
}

.warning {
  border: 1px solid $error;
}

.onlyBorder {
  border: 1px solid $error;
}
