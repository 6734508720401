@use "../../utils/styles/mixins";
@import "../../../../static/styles/variables.scss";

.Modal {
  max-height: 320px;
  padding: 15px !important;
}

.contact {
  width: 195px;
  height: 48px;
  color: $text-color;
  background-color: $secondary;
  text-align: center;
  border-radius: 40px;
  border: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 48px;
  justify-content: center;
  transition: background-color 0.1s linear;

  &:hover {
    cursor: pointer;
    background-color: $link-color-active;
  }
}

.headline {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin: 0;
  line-height: 50px;
}

.subHeadline {
  margin: 0;
  font-size: 20px;
  text-align: center;
}

.input {
  font-size: 20px;
  display: block;
  width: 150px;
  border: none;
  color: $text-color;
  padding-left: 10px;
  font-weight: 300;
  border-bottom: 1px solid $text-color;
  background-color: #0196aa;
  caret-color: $text-color;
  @include mixins.from-desktop {
    width: 300px;
    font-size: 24px;
  }
}

.highlight {
  color: $text-color;
}

input:focus {
  outline: none;
}

form {
  display: flex;
}

.label {
  color: $text-color !important;
  position: relative;
  font-size: 20px;
  font-weight: 300;
  pointer-events: none;
  left: 15px;
  top: -40px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  @include mixins.from-desktop {
    font-size: 24px;
  }
}

.input:focus ~ .label,
.input:valid ~ .label {
  top: -50px;
  font-size: 16px;
  color: #02616e;
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 15px;
  border: none;
  background-color: $text-color;
}

.bar {
  position: relative;
  display: block;
  width: 150px;
  @include mixins.from-desktop {
    width: 300px;
  }
}

.group {
  margin: 40px 0 10px 0;
  min-height: 75px;
  @include mixins.from-desktop {
    margin: 40px 30px 0;
  }
}

.bar:before,
.bar:after {
  content: "";
  height: 1px;
  width: 0;
  bottom: 0px;
  position: absolute;
  background: #02616e;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.bar:before {
  left: 50%;
}

.send {
  background-color: $secondary;
  color: $text-color;
  border-radius: 40px;
  height: 42px;
  font-weight: 600;
  margin: 0 20px 0 0;
  @include mixins.from-desktop {
    margin: 40px 30px 0;
  }
}

.bar:after {
  right: 50%;
}

input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 50%;
}

input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

@-webkit-keyframes inputHighlighter {
  from {
    background: $text-color;
  }
  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: $text-color;
  }
  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: $text-color;
  }
  to {
    width: 0;
    background: transparent;
  }
}
