@use '../../modules/common/utils/styles/mixins';
@import '../styles/variables.scss';

.filterBtn {
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid $link-color;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  margin-bottom: 15px;

  @include mixins.from-desktop {
    margin-bottom: 0;
  }

  &:last-child {
    border-right: 0;
  }

  &:hover {
    color: $link-color-hover;
    cursor: pointer;
  }

  &:active {
    color: $link-color-active;
  }
}

.search {
  position: relative;
  width: 315px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 400;
  color: $link-color;
  display: none;

  @include mixins.from-desktop {
    display: flex;
  }
}

.searchInput {
  background-color: $input-bg-color;
  border: 0;
  border-radius: 22px;
  width: 315px;
  text-indent: 48px;
}

.searchIcon {
  position: absolute;
  top: 7px;
  left: 22px;
  width: 15px;
  height: 15px;
}

.filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  margin-right: 40px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.filterBtnActive {
  color: $link-color-active;
  border-color: $link-color-hover;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
  margin-bottom: 35px;
}

.tagBtn {
  height: 40px;
  margin-right: 15px;
  border-radius: 22px;
  background-color: $input-bg-color;
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  transition: background 0.1s linear;
  margin-bottom: 15px;

  @include mixins.from-desktop {
    margin-bottom: 0;
  }

  &Active {
    background-color: $link-color-active;
  }

  &:hover {
    background-color: $secondary;
    cursor: pointer;
  }

  &:active {
    background-color: $link-color-active;
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, 290px);
  grid-gap: 1rem;
  justify-content: space-around;

  @include mixins.from-tablet {
    grid-template-columns: repeat(auto-fill, 255px);
    grid-column-gap: 30px;
    grid-row-gap: 24px;
    justify-content: flex-start;
  }

  @include mixins.from-tablet {
    grid-row-gap: 30px;
  }
}

.card {
  display: block;
  background-color: $menu-bg;
  border-radius: 10px;
  margin-bottom: 25px;
  transition: background 0.1s linear;

  &:hover {
    background-color: $secondary;
  }

  @include mixins.from-tablet {
    border-radius: 15px;
  }
}

.cardImg {
  width: 100%;
  border-radius: 10px 10px 0 0;

  @include mixins.from-tablet {
    border-radius: 15px 15px 0 0;
  }
}

.cardText {
  padding: 20px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0 0 10px 10px;

  @include mixins.from-tablet {
    font-size: 20px;
    border-radius: 0 0 15px 15px;
  }
}

.paginationWrapper {
  margin-bottom: 60px;
}

.textBlockWrapper {
  margin-bottom: 60px;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

  @include mixins.from-tablet {
    font-size: 18px;
    line-height: 27px;
  }

  @include mixins.from-desktop {
    display: flex;
    margin-bottom: 80px;
  }
}

.textBlock1 {
  margin-bottom: 24px;

  @include mixins.from-tablet {
    margin-bottom: 28px;
  }

  @include mixins.from-desktop {
    width: 540px;
    flex-shrink: 0;
    margin-right: 55px;
    margin-bottom: 30px;
  }
}

.textBlock2 {
  @include mixins.from-desktop {
    align-self: end;
  }
}

.textBlockTitle {
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-bottom: 22px;

  @include mixins.from-tablet {
    line-height: 31px;
    font-size: 26px;
    margin-bottom: 30px;
  }
}

.callToActionWrapper {
  margin-bottom: 80px;

  @include mixins.from-tablet {
    margin-bottom: 100px;
  }

  @include mixins.from-desktop {
    margin-bottom: 140px;
  }
}
