@use '../../utils/styles/mixins';
@import '../../../../static/styles/variables.scss';

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 88px;
  margin-bottom: 24px;
  padding-left: 20px;
  border-radius: 15px;
  background-color: $secondary;
  font-size: 18px;
  font-weight: 700;
  color: $text-color;

  &:hover {
    cursor: pointer;
    background-color: $link-color-active;
    color: $text-color;
  }

  &:active {
    background-color: $gray-bg;
    color: $secondary;
  }

  @include mixins.from-tablet {
    justify-content: center;
    padding-left: 0;
    border-radius: 18px;
    font-size: 20px;
  }

  @include mixins.from-desktop {
    width: 300px;
    border-radius: 22px;
  }

  @include mixins.from-desktopFull {
    width: 350px;
  }
}

.icon {
  margin-right: 18px;
}
