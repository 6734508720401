@use '../../utils/styles/mixins';
@import '../../../../static/styles/variables.scss';

.wrapper {
  display: flex;
  justify-content: flex-start;
  color: $link-color;
  margin-bottom: 28px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  @include mixins.from-tablet {
    margin-bottom: 35px;
    font-size: 14px;
    line-height: 16px;
  }
}

.link {
  margin-right: 5px;
  color: $link-color;
}

.link:not(:first-child) {
  margin-left: 5px;
}

.link:hover {
  color: $link-color-hover;
}
