@use '../../utils/styles/mixins';
@import '../../../../static/styles/variables.scss';

.menu {
  background-color: $menu-bg;

  @include mixins.from-desktop {
    width: 800px!important;
  }
}

.nav > .accordion-button {
  background-color: $menu-bg!important;
  color: $text-color !important;
  font-size: 26px;
  line-height: 31px;
  font-weight: 700;
}

.nav > .accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3e") !important;
  margin-left: 10px;
  margin-top: 10px;
}

.hamburger-lines {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  height: 26px;
  width: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include mixins.from-desktop {
    margin: 7px 50px 0 0;
  }
}

.accordion-button:focus {
  box-shadow: none !important;
}

li {
  list-style-type: none;
  cursor: pointer;
  margin-bottom: 18px;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
}

#react-burger-cross-btn {
  height: 20px !important;
  width: 20px !important;
}

.bm-cross {
  height: 25px !important;
  width: 2px !important;
  background-color: $text-color;
  @include mixins.from-tablet {
    height: 30px !important;

  }
}

.bm-cross-button {
  left: 35px !important;
  top: 40px !important;


  @include mixins.from-desktop {
    left: 165px!important;
  }
}

.nav > .accordion {
  max-width: 400px;
  font-size: 26px;
  line-height: 31px;
  margin-bottom: 18px;
  --bs-accordion-border-width: 0 !important;
  --bs-accordion-bg: $menu-bg!important;
  --bs-accordion-color: $text-color !important;
  --bs-accordion-btn-color: --bs-body-bg !important;
  @include mixins.from-tablet {
    font-size: 24px;
    line-height: 45px;
  }
}

.nav .accordion-body {
  @include mixins.from-desktop {
    --bs-accordion-body-padding-y: 20px;
  }
}

.nav .accordion-body li {
  font-size: 16px;
  padding-left: 20px;
}

.nav .accordion-body a:hover {
  transition: color 0.1s linear;
  color: $link-color-hover;
}

.nav .accordion-button {
  justify-content: flex-start;
  margin-left: 0;
  font-size: 26px!important;
  line-height: 31px;

  @include mixins.from-desktop {
    font-size: 50px!important;
    line-height: 59px;
  }
}

.accordion-item {
  border: none !important;
}

.menu-logo {
  max-width: 180px;
  max-height: 180px;
  margin: 0 auto;
}

.menu__box {
  height: 100%;
  flex-direction: column;
  justify-content: center;

  @include mixins.from-desktop {
    width: 800px;
  }
}

.menu-cont {
  margin-top: 100px;
  justify-content: center;

  @include mixins.from-desktop {
    margin-top: 145px;
  }
}

.menu__item {
  color: $text-color;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  font-weight: 700;
  text-transform: uppercase;
  transition: color 0.1s linear;

  &:hover {
    color: $link-color-hover;
  }

  @include mixins.from-tablet {
    font-size: 50px;
    line-height: 59px;
  }
}

.menu-bottom {
  padding-left: 35px;
}

.menu-bottom > .menu__item {
  display: block;
  font-size: 18px;
  line-height: 21px;
  text-transform: unset;

  &:first-child {
    margin-bottom: 20px;
  }
}

.menu-bottom > .Modal {
  margin-top: 30px;
  padding-top: 0!important;
  padding-bottom: 50px!important;
  padding-left: 0!important;
}

.nav {
  display: block!important;
  padding-left: 35px!important;
  padding-right: 5px;
  overflow-x: hidden;

  @include mixins.from-desktop {
    padding-left: 165px!important;
  }
}

.menu__box > .menu-bottom {
  margin-top: auto;

  @include mixins.from-desktop {
    padding-left: 165px;
  }
}

.fa-links-in-nav:hover {
  .fa {
    color: $link-color-hover!important;
  }
}

.fa-links-in-nav:active {
  .fa {
    color: $link-color-active!important;
  }
}
