@use '../../modules/common/utils/styles/mixins';
@import '../styles/variables.scss';

#concert-swiper  {
  order: 2;
}

#concert-swiper-nav {
  #previousButton div,
  #nextButton div {
    display: flex;
  }
}
