@use '../../modules/common/utils/styles/mixins';
@import '../styles/variables.scss';

.wrapper {
  margin-bottom: 80px;
}

.mainBlock {
  margin-bottom: 60px;

  @include mixins.from-tablet {
    margin-bottom: 80px;
  }

  @include mixins.from-desktop {
    display: flex;
    height: 493px;
  }
}

.adressBlock {
  min-height: 343px;
  background-color: $menu-bg;
  border-radius: 15px 15px 0px 0px;
  padding: 40px 25px 35px;

  @include mixins.from-tablet {
    padding-bottom: 60px;
    padding-left: 95px;
    padding-top: 60px;
  }

  @include mixins.from-desktop {
    width: 540px;
    border-radius: 15px 0 0 15px;
  }

  @include mixins.from-desktop {
    padding-top: 97px;
    padding-bottom: 105px;
    padding-left: 95px;
  }
}

.phones {
  display: flex;
  margin-bottom: 25px;
}

.phonesIco,
.adressIco,
.mailIco {
  width: 26px;
  height: 26px;
  margin-top: 10px;
  margin-right: 16px;

  @include mixins.from-tablet {
    width: 36px;
    height: 36px;
    margin-top: 5px;
    margin-right: 20px;
  }
}

.phonesText {
  font-size: 18px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;

  @include mixins.from-tablet {
    font-size: 20px;
    line-height: 36px;
  }
}

.mail {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;

  @include mixins.from-tablet {
    font-size: 18px;
    line-height: 32px;
  }
}

.mailIco {
  margin-top: 0;
}

.adress {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 25px;

  @include mixins.from-tablet {
    font-size: 18px;
    line-height: 32px;
  }
}

.adressIco {
  margin-top: 0;
}

.map {
  height: 210px;
  overflow: hidden;
  border-radius: 0px 0px 15px 15px;

  @include mixins.from-tablet {
    height: 355px;
  }

  @include mixins.from-desktop {
    height: 100%;
    border-radius: 0px 15px 15px 0;
  }
}

.mapImage {
  width: 100%;
  object-position: center -15px;

  @include mixins.from-desktop {
    object-position: center center;
    height: 100%;
  }
}

.socials {
  display: flex;
  height: 32px;
  margin-top: 25px;
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $secondary;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  fill: $text-color;
  margin-right: 10px;

  &:hover {
    fill: $link-color-hover;
  }

  &:active {
    fill: $link-color-active;
  }

  svg {
    width: 16px;
  }

  @include mixins.from-tablet {
    width: 36px;
    height: 36px;
  }
}
