@use '../../utils/styles/mixins';
@import '../../../../static/styles/variables.scss';

.button {
  width: 100%;
  border: 0;
  height: 48px;
  background-color: $link-color-active;
  font-size: 14px;
  font-weight: 700;
  border-radius: 40px;
  transition: background 0.1s linear;

  @include mixins.from-tablet {
    font-size: 16px;
  }

  &:hover {
    background-color: $secondary;
  }

  &:active {
    background-color: $gray-bg;
    color: $link-color-active;
  }
}

.disable {
  background-color: $disable-bg;
  color: $disable-text-color;

  &:hover {
    cursor: default;
  }
}
