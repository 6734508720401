@use '../../utils/styles/mixins';
@import '../../../../static/styles/variables.scss';

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: center;
  margin-top: 15px;

  @include mixins.from-tablet {
    margin-bottom: 50px;
  }

  @include mixins.from-desktop {
    grid-template-columns: 36px 250px 99px 85px 190px;
    margin-bottom: 35px;
    margin-top: 20px;
  }
}

.burgerWrapper {
  display: flex;
  align-items: center;
}

.burger {
  width: 26px;
  height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  @include mixins.from-tablet {
    width: 34px;
    height: 20px;
  }
}

.burgerLine {
  display: block;
  height: 2px;
  width: 100%;
  border-radius: 10px;
  background: $text-color;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  @include mixins.from-desktop {
    grid-column-start: 3;
    grid-column-end: 4;
  }
}

.logo {
  width: 68px;
  height: 68px;

  @include mixins.from-tablet {
    width: 80px;
    height: 80px;
  }

  @include mixins.from-desktop {
    width: 99px;
    height: 99px;
  }
}

.socials {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;

  @include mixins.from-tablet {
    width: 85px;
    margin-left: 0;
    justify-self: flex-end;
  }
}

.menuLinks {
  grid-column-start: 1;
  grid-column-end: 4;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @include mixins.from-tablet {
    grid-column-start: 1;
    grid-column-end: 3;
    justify-content: flex-start;
    margin-top: 25px;
  }

  @include mixins.from-desktop {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    margin-top: 0;
    align-self: center;
  }
}

.menuLink {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: $text-color;
  margin-right: 24px;

  &:hover {
    color: $link-color-hover;
  }

  &:active {
    color: $link-color-active;
  }

  &:last-child {
    margin-right: 0;
  }

  @include mixins.from-tablet {
    font-size: 16px;
    line-height: 19px;
  }
}

.regionSelectWrapper {
  grid-column-start: 1;
  grid-column-end: 4;
  margin-top: 20px;
  margin-bottom: 20px;

  @include mixins.from-tablet {
    grid-column-start: 3;
    grid-column-end: 4;
    margin-top: 16px;
    margin-bottom: 0;
  }

  @include mixins.from-desktop {
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    margin-top: 0;
    align-self: center;
  }
}

.navLink {
  color: $text-color;
}
