@import '../../../../static/styles/variables.scss';

.tile-link .svg-icon {
  fill: $link-color-active;
  transition: fill 0.1s linear;
}

.tile-link:hover .svg-icon {
  fill: $text-color;
}

.tile-link:active .svg-icon {
  fill: $link-color-active;
}
