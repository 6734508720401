$text-color: #F2F2F2;
$link-color: #6B7177;
$link-color-hover: #1F5F7C;
$link-color-active: #3297C2;
$input-bg-color: #121C25;
$menu-bg: #0B1B28;
$secondary: #133447;
$main-bg: #020C14;
$gray-color: #7B8289;
$gray-bg: #D8E2E7;
$input-bg: #23323D;
$disable-bg: #252C33;
$disable-text-color: #656C73;
$error: #BF4343;
$error-text: #D53F3F;
