@use '../../modules/common/utils/styles/mixins';
@import '../styles/variables.scss';

.cards {
  margin-bottom: 60px;

  @include mixins.from-tablet {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    margin-bottom: 80px;
  }

  @include mixins.from-desktop {
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 130px;
  }
}

.card {
  width: 100%;
  margin-bottom: 25px;
  background-color: $menu-bg;
  border-radius: 10px;

  @include mixins.from-desktop {
    width: 300px;
  }

  @include mixins.from-desktopFull {
    width: 350px;
  }
}

.cardImg {
  width: 100%;
  height: 215px;
  border-radius: 10px 10px 0 0;

  @include mixins.from-tablet {
    height: 189px;
  }
}

.cardBody {
  padding: 20px 16px 30px;

  @include mixins.from-desktop {
    padding: 30px 24px 45px;
  }
}

.cardTitle {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;

  @include mixins.from-tablet {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 23px;
  }

  @include mixins.from-desktop {
    line-height: 24px;
  }
}

.cardDescription {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: $link-color;

  @include mixins.from-tablet {
    font-size: 16px;
    line-height: 19px;
  }
}

.tiles {
  margin-top: 60px;
  margin-bottom: 80px;

  @include mixins.from-tablet {
    margin-bottom: 100px;
    margin-top: 80px;
  }

  @include mixins.from-desktop {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 140px;
  }

  a {
    @include mixins.from-desktop {
      width: 470px;
    }

    @include mixins.from-desktopFull {
      width: 540px;
    }
  }
}
