@use '../../modules/common/utils/styles/mixins';
@import '../styles/variables.scss';

.jumbotron {
  position: relative;
  width: 100%;
  border-radius: 10px;
  background-color: $menu-bg;
  margin-bottom: 50px;

  @include mixins.from-tablet {
    margin-bottom: 60px;
  }

  @include mixins.from-desktop {
    display: flex;
    height: 540px;
    border-radius: 15px;
    margin-bottom: 80px;
  }
}

.jumbotronImg {
  width: 100%;
  height: 220px;
  border-radius: 10px 10px 0 0;

  @include mixins.from-tablet {
    height: 400px;
  }

  @include mixins.from-desktop {
    width: 445px;
    height: 100%;
    border-radius: 15px 0 0 15px;
  }
}

.jumbotronAgeRestrictions {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 29px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(242, 242, 242, 0.15);
  border: 1px solid rgba(242, 242, 242, 0.5);
  border-radius: 50%;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  line-height: 29px;

  @include mixins.from-tablet {
    width: 40px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
  }
}

.jumbotronContent {
  padding-top: 24px;
  padding-bottom: 30px;
  padding-left: 16px;
  padding-right: 16px;

  @include mixins.from-tablet {
    padding-top: 36px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 40px;
  }

  @include mixins.from-desktop {
    padding: 103px 95px;
  }
}

.jumbotronTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-bottom: 16px;

  @include mixins.from-tablet {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 24px;
  }

  @include mixins.from-desktop {
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 28px;
  }
}

.jumbotronDescription {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  @include mixins.from-tablet {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  @include mixins.from-desktop {
    margin-bottom: 28px;
  }
}

.jumbotronPlace,
.jumbotronDate {
  display: flex;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 600;

  @include mixins.from-tablet {
    font-size: 18px;
  }
}

.jumbotronDate {
  margin-bottom: 26px;

  @include mixins.from-tablet {
    margin-bottom: 30px;
  }

  @include mixins.from-desktop {
    margin-bottom: 40px;
  }
}

.jumbotronBtn {
  width: 142px;
  height: 40px;
  background-color: $link-color-active;
  border-radius: 40px;
  color: $text-color;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-align: center;
  transition: all 0.1s linear;

  &:hover {
    cursor: pointer;
    background-color: $secondary;
    color: white;
  }

  &:active {
    background-color: $gray-bg;
    color: $link-color-active;
  }

  @include mixins.from-tablet {
    width: 180px;
    height: 50px;
    font-size: 16px;
    line-height: 50px;
  }
}

.geoIcon,
.calendarIcon {
  width: 13px;
  height: 16px;
  margin-top: -2px;
  margin-right: 12px;
}

.content {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 60px;

  @include mixins.from-tablet {
    line-height: 24px;
    margin-bottom: 80px;
  }

  @include mixins.from-desktop {
    display: flex;
  }
}

.contentText {
  margin-bottom: 24px;

  @include mixins.from-desktop {
    width: 380px;
    flex-shrink: 0;
  }
}

.sliderImg {
  width: 100%;
  height: 214px;
  margin-bottom: 24px;
  border-radius: 10px;

  @include mixins.from-tablet {
    height: 400px;
    border-radius: 15px;
    margin-bottom: 30px;
  }

  @include mixins.from-desktop {
    width: 665px;
    flex-shrink: 0;
    margin-bottom: 0;
  }
}

.swiperNav {
  display: flex;
  justify-content: center;
  align-items: center;

  @include mixins.from-desktop {
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 10px;
  }
}

.prevBtn,
.nextBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  background-color: $menu-bg;
  border-radius: 50%;
  transition: all 0.1s linear;

  &:hover {
    cursor: pointer;
    background-color: $link-color-active;
  }

  &:hover svg {
    stroke: white;
  }

  &:active svg {
    background-color: $gray-bg;
    stroke: $secondary;
  }

  svg {
    width: 18px;
    height: 18px;
    stroke: $gray-color;
  }
}

.nextBtn {
  @include mixins.from-desktop {
    transform: rotate(90deg);
  }
}

.prevBtn {
  margin-right: 10px;

  @include mixins.from-desktop {
    margin-right: 0;
    transform: rotate(90deg);
    margin-bottom: 10px;
  }
}

.tiles {
  margin-top: 60px;
  margin-bottom: 80px;

  @include mixins.from-tablet {
    margin-bottom: 100px;
    margin-top: 80px;
  }

  @include mixins.from-desktop {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 140px;
  }
}

.photoreportBtn {
  width: 100%;
  height: 40px;
  background-color: $secondary;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-align: center;
  box-shadow: 0px 15px 25px rgba(1, 15, 30, 0.5);
  transition: background 0.1s linear;

  &:hover {
    background-color: $link-color-active;
    cursor: pointer;
  }

  &:active {
    background-color: $gray-bg;
    color: $secondary;
  }

  @include mixins.from-tablet {
    font-size: 16px;
    width: 130px;
    height: 42px;
    line-height: 42px;
  }

  @include mixins.from-desktop {
    width: 213px;
  }
}
