@use '../../modules/common/utils/styles/mixins';

.accordion,
.accordion-item {
  --bs-accordion-bg: $main-bg!important;
  --bs-accordion-active-bg: $main-bg!important;
}

.accordion {
  --bs-accordion-border-width: 0!important;
  --bs-accordion-active-color: $text-color!important;
  --bs-accordion-btn-color: $text-color!important;
  --bs-accordion-btn-padding-y: 3px!important;
  --bs-accordion-btn-padding-x: 0!important;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2722%27 height=%2711%27 viewBox=%270 0 22 11%27 fill=%27%237B8289%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M12.5903 0.402726C11.9882 -0.134245 11.0121 -0.134245 10.41 0.402726L1.16004 8.65273C0.557981 9.1897 0.557981 10.0603 1.16004 10.5973C1.7621 11.1342 2.73823 11.1342 3.34029 10.5973L11.5002 3.31954L19.66 10.5973C20.2621 11.1342 21.2382 11.1342 21.8403 10.5973C22.4423 10.0603 22.4423 9.1897 21.8403 8.65273L12.5903 0.402726Z%27/%3e%3c/svg%3e") !important;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 22 11%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M12.5903 0.402726C11.9882 -0.134245 11.0121 -0.134245 10.41 0.402726L1.16004 8.65273C0.557981 9.1897 0.557981 10.0603 1.16004 10.5973C1.7621 11.1342 2.73823 11.1342 3.34029 10.5973L11.5002 3.31954L19.66 10.5973C20.2621 11.1342 21.2382 11.1342 21.8403 10.5973C22.4423 10.0603 22.4423 9.1897 21.8403 8.65273L12.5903 0.402726Z%27/%3e%3c/svg%3e") !important;
}

.accordion-button {
  font-size: 21px!important;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  margin-left: 13px;
  margin-bottom: 5px;

  @include mixins.from-tablet {
    font-size: 56px!important;
  }

  &::after {
    margin-left: 9px!important;
    margin-top: 3px;
    transform-origin: 50% 30%;

    @include mixins.from-tablet {
      margin-left: 25px!important;
    }
  }
}

.accordion-body {
  --bs-accordion-body-padding-y: 10px;
  --bs-accordion-body-padding-x: 0;
  margin-bottom: 15px;
}

.accordion-body > li {
  line-height: 1;
  margin-top: -4px;
}
