@use '../../utils/styles/mixins';
@import '../../../../static/styles/variables.scss';

.wrapper {
  height: 165px;
  border-top: 1px solid rgba(242, 242, 242, 0.3);
  padding-top: 30px;
  margin-top: auto;

  @include mixins.from-tablet {
    height: 194px;
    padding-top: 40px;
  }

  @include mixins.from-desktop {
    height: 54px;
    padding-top: 16px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mixins.from-desktop {
    flex-direction: row;
    justify-content: space-between;
  }
}

.links {
  display: flex;
  justify-content: space-between;
  width: 230px;
  margin-bottom: 30px;

  @include mixins.from-tablet {
    width: 330px;
    margin-bottom: 37px;
  }

  @include mixins.from-desktop {
    width: 250px;
    order: 1;
    margin-bottom: 0;
  }
}

.link {
  color: $text-color;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  &:hover {
    color: $link-color-hover;
  }

  &:active {
    color: $link-color-active;
  }

  @include mixins.from-tablet {
    font-size: 16px;
    line-height: 19px;
  }
}

.rights,
.policyLink {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;

  @include mixins.from-desktop {
    width: 200px;
  }
}

.rights {
  margin-bottom: 25px;

  @include mixins.from-desktop {
    order: 0;
    margin-bottom: 0;
  }
}

.policyLink {
  color: $text-color;
  text-decoration: underline!important;

  @include mixins.from-desktop {
    order: 2;
    text-align: right;
  }

  &:hover {
    color: $link-color-hover;
  }

  &:active {
    color: $link-color-active;
  }
}
