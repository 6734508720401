@use '../../utils/styles/mixins';
@import '../../../../static/styles/variables.scss';

.wrapper {
  padding: 30px 16px;
  background-color: $menu-bg;
  border-radius: 10px;

  @include mixins.from-tablet {
    padding: 35px 24px;
  }

  @include mixins.from-desktop {
    padding: 35px;
  }
}

.concertsTitle {
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.01em;
  text-align: left;

  @include mixins.from-tablet {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 40px;
  }

  @include mixins.from-desktop {
    font-size: 26px;
    line-height: 31px;
  }
}

.item {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 0.5px solid rgba(255, 255, 255, .5);

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  @include mixins.from-tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.date {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  @include mixins.from-tablet {
    width: 170px;
    font-size: 18px;
    line-height: 22px;
  }

  @include mixins.from-desktop {
    width: 335px;
  }
}

.info {
  margin-bottom: 18px;

  @include mixins.from-tablet {
    margin-right: auto;
    margin-bottom: 0;
  }
}

.countryAndCity {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  @include mixins.from-tablet {
    font-size: 18px;
    line-height: 22px;
  }
}

.location {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

  @include mixins.from-desktop {
    font-size: 16px;
    line-height: 19px;
  }
}

.more {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}

.arrowIcon {
  margin-left: 8px;
}
