@use '../../modules/common/utils/styles/mixins';
@import '../styles/variables.scss';

.artist-left {
  margin-bottom: 20px;
}

.swiper-navigation {
  display: flex;
  justify-content: center;
  margin: 30px;
  align-items: center;
}

.artist-text {
  display: flex;
  max-width: 1090px;
  margin: 0 auto 40px;
  @include mixins.from-tablet {
  }
}


.artist-text h5 {
  color: $text-color;
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;
  @include mixins.from-tablet {
    font-size: 26px;
  }
}

.artist-text p {
  font-weight: 400;
  line-height: 26px;
  font-size: 14px;
  @include mixins.from-tablet {
    font-size: 22px;
    line-height: 32px;

  }
}

.artist-text span {
  font-weight: 700;
}

.button-buy-ticket {
  color: $text-color;
  background-color: #00A7BA;
  text-align: center;
  border-radius: 30px;
  border: 1px solid $text-color;
  padding: 10px 20px;
  margin-bottom: auto;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

}

.slider-item-image,
.slider-item-video {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

