@use '../../modules/common/utils/styles/mixins';
@import '../styles/variables.scss';

.artist {
  border: none !important;
  --bs-card-bg: transparent !important;
  border-radius: 20px !important;
  transition: all linear 200ms;
  width: 360px;
  @include mixins.from-desktop {
    width: 443px;

  }
}

.img {
  width: 100%;
  height: 270px;
  object-fit: cover;
  @include mixins.from-desktop {
    height: 370px;
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, 290px);
  grid-gap: 1rem;
  justify-content: space-around;

  @include mixins.from-tablet {
    grid-template-columns: repeat(auto-fill, 255px);
    grid-column-gap: 30px;
    grid-row-gap: 24px;
    justify-content: flex-start;
  }

  @include mixins.from-desktop {
    grid-row-gap: 30px;
    margin-bottom: 60px;
  }
}

.card {
  position: relative;
  display: block;
  background-color: $menu-bg;
  border-radius: 10px;
  margin-bottom: 25px;
  transition: background 0.1s linear;
}

.cardBody {
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 30px;
}

.cardImg {
  width: 100%;
  height: 220px;
  border-radius: 10px 10px 0 0;
}

.cardTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.cardText {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: $link-color;
  margin-bottom: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.date {
  color: $link-color;
}

.age {
  position: absolute;
  width: 29px;
  height: 29px;
  top: 15px;
  left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(242, 242, 242, 0.15);
  border: 1px solid rgba(242, 242, 242, 0.5);
  border-radius: 50%;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  line-height: 29px;
}

.link {
  display: block;
  text-align: center;
  width: 142px;
  height: 40px;
  background-color: $link-color-active;
  color: $text-color;
  border-radius: 40px;
  font-weight: 700;
  font-size: 14px;
  line-height: 40px;
  transition: all 0.1s linear;

  @include mixins.from-tablet {
    width: 156px;
    font-size: 16px;
  }

  &:hover {
    background-color: $secondary;
    color: $text-color;
  }

  &:active {
    background-color: $gray-bg;
    color: $link-color-active;
  }
}

.features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 80px;

  @include mixins.from-tablet {
    margin-bottom: 100px;
  }

  @include mixins.from-desktop {
    margin-top: 100px;
    margin-bottom: 140px;
    justify-content: space-between;
  }
}

.feature {
  margin-bottom: 60px;

  @include mixins.from-tablet {
    margin-bottom: 70px;
  }

  @include mixins.from-desktop {
    display: flex;
    flex-direction: column;
    width: calc(50% - 15px);
    margin-bottom: 0;
  }
}

.featPromo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 22px;

  @include mixins.from-tablet {
    margin-bottom: 25px;
  }
}

.promoText {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 24px;

  @include mixins.from-tablet {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 30px;
  }
}

.promoH1 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  @include mixins.from-tablet {
    font-size: 20px;
    line-height: 24px;
  }
}

.promoLink {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $link-color-active;
  width: 194px;
  height: 48px;
  font-weight: 700;
  font-size: 14px;
  line-height: 48px;
  color: $text-color;
  border-radius: 40px;

  &:hover {
    background-color: $secondary;
    color: $text-color;
  }

  &:active {
    background-color: $gray-bg;
    color: $link-color-active;
  }

  @include mixins.from-tablet {
    width: 212px;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
  }

  @include mixins.from-desktop {
    width: 200px;
    margin-top: auto;
  }
}

.promoLink:hover {
  color: $text-color !important;
}

.concertsHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  @include mixins.from-desktop {
    flex-direction: row;
  }
}

.geo {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include mixins.from-tablet {
    flex-direction: row;
    margin: 0;
    margin-left: auto;
  }
}

.geo p {
  margin: 0;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
}

.outline {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3e") !important;
  text-transform: uppercase;
  width: auto !important;
  color: $text-color !important;
  background-color: #00A7BA !important;
  text-align: center;
  border-radius: 30px !important;
  border: 1px solid $text-color !important;
  margin-left: 15px;
  padding: 5px 35px !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 21px;
}

.select {
  width: auto !important;
  color: $text-color !important;
  background-color: #00A7BA !important;
  text-align: center;
  border-radius: 30px !important;
  border: 1px solid $text-color !important;
  margin-left: 15px;
  padding: 5px 35px !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 21px;
}

.geoOutline {
  color: $text-color;
  background-color: #00A7BA;
  text-align: center;
  border-radius: 30px;
  border: 1px solid $text-color;
  padding: 5px 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin-left: 15px;
  @include mixins.from-tablet {
    margin-top: 0px !important;
  }
}

.filters {
  display: flex;
  justify-content: flex-start;
  margin: 30px 0;
  flex-wrap: wrap;

  @include mixins.from-desktop {
    margin-bottom: 45px;
  }

  > div {
    margin-bottom: 20px;
  }
}

.searchInput {
  color: $text-color;
  background-color: #00A7BA;
  text-align: center;
  border-radius: 30px;
  border: 1px solid $text-color;
  padding: 10px 0;
  font-weight: 700;
  font-size: 14px;
  margin-top: 10px;
}

.searchInput::placeholder {
  color: $text-color;
}

.datePicker {
  cursor: pointer;
  color: $text-color;
  background-color: $input-bg-color;
  text-align: center;
  border-radius: 30px;
  appearance: none;
  outline: 0;
  border: 0;
  padding: 16px 1px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  max-width: 120px;
  height: 38px;
  margin-right: 20px;
}

select {
  color: $text-color;
  background-color: #00A7BA;
  text-align: center;
  border-radius: 30px;
  border: 1px solid $text-color;
  margin-left: 15px !important;
  padding: 15px 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  @include mixins.from-desktop {
    margin-top: 10px;
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 68px;
  height: 68px;
  margin-right: 25px;
  background-color: $secondary;
  border-radius: 15px;
  fill: $link-color-active;

  @include mixins.from-tablet {
    width: 88px;
    height: 88px;
  }
}
