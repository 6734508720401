@use '../../modules/common/utils/styles/mixins';
@import '../styles/variables.scss';

.texts {
  @include mixins.from-desktop {
    display: flex;
  }
}
.text {
  margin-bottom: 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;

  @include mixins.from-tablet {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 27px;
  }
}

.text2 {
  margin-bottom: 45px;
  padding: 25px 35px;
  border-radius: 10px;
  background-color: $secondary;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  @include mixins.from-tablet {
    margin-bottom: 80px;
    padding: 43px 38px;
    font-size: 20px;
    line-height: 24px;
    border-radius: 15px;
  }

  @include mixins.from-desktop {
    width: 540px;
    flex-shrink: 0;
    margin-left: 30px;
  }
}

.cards {
  margin-bottom: 60px;

  @include mixins.from-tablet {
    margin-bottom: 80px;
  }

  @include mixins.from-desktop {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 100px;
  }
}

.card {
  width: 100%;
  margin-bottom: 25px;
  background-color: $menu-bg;
  border-radius: 15px;

  @include mixins.from-desktop {
    width: 300px;
  }

  @include mixins.from-desktopFull {
    width: 350px;
  }
}

.cardImg {
  width: 100%;
  height: 215px;
  border-radius: 15px 15px 0 0;

  @include mixins.from-tablet {
    height: 260px;
  }
}

.cardBody {
  padding: 20px 16px 30px;

  @include mixins.from-tablet {
    padding: 30px 24px 30px;
  }

  @include mixins.from-desktop {
    padding: 30px 24px 45px;
  }
}

.cardTitle {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;

  @include mixins.from-tablet {
    font-size: 20px;
    line-height: 23px;
  }

  @include mixins.from-desktop {
    line-height: 24px;
  }
}

.cardDescription {
  margin-bottom: 26px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: $link-color;

  @include mixins.from-tablet {
    font-size: 16px;
    line-height: 19px;
  }
}

.cardBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  background-color: $link-color-active;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 700;
  color: $text-color;
  transition: all 0.1s linear;

  @include mixins.from-tablet {
    font-size: 16px;
  }

  &:hover {
    background-color: $secondary;
    color: $text-color;
  }

  &:active {
    background-color: $gray-bg;
    color: $link-color-active;
  }
}

.tiles {
  margin-top: 60px;
  margin-bottom: 80px;

  @include mixins.from-tablet {
    margin-bottom: 100px;
  }

  @include mixins.from-desktop {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 140px;
  }
}
