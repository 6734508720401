@use '../../modules/common/utils/styles/mixins';
@import '../styles/variables.scss';

.artist-navbar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include mixins.from-tablet {
    flex-direction: row;
  }
}
