@mixin from-tablet {
  @media (min-width: 640px) { @content; }
}

@mixin from-desktop {
  @media (min-width: 1024px) { @content; }
}

@mixin from-desktopFull {
  @media (min-width: 1280px) { @content; }
}
