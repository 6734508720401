@use '../../modules/common/utils/styles/mixins';
@import '../styles/variables.scss';

.jumbotron {
  position: relative;
  width: 100%;
  border-radius: 10px;
  background-color: $menu-bg;
  margin-bottom: 50px;

  @include mixins.from-tablet {
    margin-bottom: 60px;
  }

  @include mixins.from-desktop {
    display: flex;
    height: 540px;
    border-radius: 15px;
    margin-bottom: 80px;
  }
}

.jumbotronImg {
  width: 100%;
  height: 220px;
  border-radius: 10px 10px 0 0;

  @include mixins.from-tablet {
    height: 400px;
  }

  @include mixins.from-desktop {
    width: 445px;
    height: 100%;
    border-radius: 15px 0 0 15px;
  }
}

.jumbotronAgeRestrictions {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 29px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(242, 242, 242, 0.15);
  border: 1px solid rgba(242, 242, 242, 0.5);
  border-radius: 50%;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  line-height: 29px;

  @include mixins.from-tablet {
    width: 40px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
  }
}

.jumbotronContent {
  padding-top: 24px;
  padding-bottom: 30px;
  padding-left: 16px;
  padding-right: 16px;

  @include mixins.from-tablet {
    padding-top: 36px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 40px;
  }

  @include mixins.from-desktop {
    padding: 103px 95px;
  }
}

.jumbotronTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-bottom: 16px;

  @include mixins.from-tablet {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 24px;
  }

  @include mixins.from-desktop {
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 28px;
  }
}

.jumbotronDescription {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  @include mixins.from-tablet {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  @include mixins.from-desktop {
    margin-bottom: 28px;
  }
}

.jumbotronPlace {
  display: flex;
  margin-bottom: 26px;
  font-size: 16px;
  font-weight: 600;

  @include mixins.from-tablet {
    font-size: 18px;
  }
}

.jumbotronButtons {
  @include mixins.from-tablet {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }
}

.jumbotronBtn,
.jumbotronBtn2 {
  width: 142px;
  height: 40px;
  background-color: $link-color-active;
  border-radius: 40px;
  color: $text-color;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-align: center;
  transition: all 0.1s linear;

  &:hover {
    cursor: pointer;
    background-color: $secondary;
    color: white;
  }

  &:active {
    background-color: $gray-bg;
    color: $link-color-active;
  }

  @include mixins.from-tablet {
    width: auto;
    height: 50px;
    font-size: 16px;
    line-height: 50px;
  }
}

.jumbotronBtn {
  margin-bottom: 12px;
}

.jumbotronBtn2 {
  background-color: $secondary;

  &:hover {
    background-color: $link-color-active;
  }

  &:active {
    background-color: $gray-bg;
  }
}

.prevBtn,
.nextBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  background-color: $menu-bg;
  border-radius: 50%;
  transition: all 0.1s linear;

  &:hover {
    cursor: pointer;
    background-color: $link-color-active;
  }

  &:hover svg {
    stroke: white;
  }

  &:active svg {
    background-color: $gray-bg;
    stroke: $secondary;
  }

  svg {
    width: 18px;
    height: 18px;
    stroke: $gray-color;
  }
}

.geoIcon {
  width: 13px;
  height: 16px;
  margin-top: -2px;
  margin-right: 12px;
}

.countryName {
  padding-right: 5px;
}

.prevBtn {
  margin-right: 10px;
}

.sliderImg {
  width: 100%;
  height: 214px;
  border-radius: 10px;

  @include mixins.from-tablet {
    height: 400px;
    border-radius: 15px;
  }

  @include mixins.from-desktop {
    width: 326px;
    flex-shrink: 0;
  }

  @include mixins.from-desktopFull {
    width: 370px;
  }
}

.textBlock {
  margin-bottom: 60px;

  @include mixins.from-desktop {
    display: flex;
    margin-top: 70px;
  }
}

.textBlockTitle {
  margin-bottom: 22px;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-align: left;

  @include mixins.from-tablet {
    margin-bottom: 30px;
    font-size: 26px;
    line-height: 31px;
  }

  @include mixins.from-desktop {
    margin-right: 95px;
    line-height: 31px;
  }
}

.textBlockDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

  @include mixins.from-tablet {
    font-size: 18px;
    line-height: 27px;
  }

  @include mixins.from-desktop {
    width: 635px;
  }
}

.wrapperConcertsSummaries {
  margin-top: 60px;
  margin-bottom: 60px;
}

.photoreportBtn {
  background-color: $link-color-active;
  width: 100%;
  border-radius: 40px;
  height: 40px;
  color: $text-color;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-align: center;

  &:hover {
    cursor: pointer;
    background-color: $secondary;
    color: white;
  }

  &:active {
    background-color: $gray-bg;
    color: $link-color-active;
  }

  @include mixins.from-tablet {
    width: 130px;
    font-size: 16px;
  }

  @include mixins.from-desktop {
    width: 160px;
  }
}

.tiles {
  margin-top: 60px;
  margin-bottom: 80px;

  @include mixins.from-tablet {
    margin-bottom: 100px;
    margin-top: 80px;
  }

  @include mixins.from-desktop {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 140px;
  }

  a {
    @include mixins.from-desktop {
      width: 470px;
    }

    @include mixins.from-desktopFull {
      width: 540px;
    }
  }
}

.otherArtistTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-bottom: 30px;

  @include mixins.from-tablet {
    margin-bottom: 50px;
    font-size: 26px;
  }
}

.otherArtistsBody {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 30px;

  @include mixins.from-tablet {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }

  @include mixins.from-desktop {
    grid-template-columns: repeat(5, 1fr);
  }
}

.otherArtistItem {
  border-radius: 10px;
  &:nth-child(4) {
    display: none;
  }

  @include mixins.from-tablet {
    &:nth-child(3) {
      display: none;
    }
  }

  @include mixins.from-desktop {
    &:nth-child(3),
    &:nth-child(4) {
      display: block;
    }
  }
}

.otherArtistName {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;

  @include mixins.from-tablet {
    font-size: 18px;
    line-height: 22px;
  }
}

.otherArtistImg {
  aspect-ratio: 1;
  max-width: 100%;
  border-radius: 10px;
}

.otherArtistsBtn {
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $secondary;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0.01em;
  text-align: center;
  border-radius: 10px;
  color: $text-color;
  padding: 4px;

  &:hover {
    background-color: $link-color-active;
    color: $text-color;
  }

  &:active {
    background-color: $gray-bg;
    color: $secondary;
  }

  @include mixins.from-tablet {
    font-size: 18px;
    line-height: 20px;
  }

  @include mixins.from-desktop {
    line-height: 22px;
    padding: 20px;
  }
}
