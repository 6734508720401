@use '../../modules/common/utils/styles/mixins';
@import '../styles/variables.scss';

.questions > .accordion {
    --bs-accordion-border-width: 0 !important;
    --bs-accordion-bg: #00A7BA !important;
    --bs-accordion-color: $text-color !important;
    --bs-accordion-btn-color: --bs-body-bg !important;
}

.question > .accordion-button {
    margin: 5px;
    background-color: #00A7BA !important;
    color: $text-color !important;
    font-size: 22px !important;
    font-weight: 400;
    min-width: 355px;
}

.question > .accordion-button::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3e") !important;

    @include mixins.from-desktop {
        margin-right: 30px;
    }
}

.question > .accordion-button:not(.collapsed)::after {
    transform: rotate(-90deg) !important;
}

.before-title {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 50px;
}

.question:first-child {
    border-top: 1px solid rgba(255, 255, 255, .5);
}

.last {
    border-bottom: 1px solid rgba(255, 255, 255, .5);
}

.accordion-item {
    border: none !important;

}

.question-footer {
    font-weight: 800;
    font-size: 28px;
    line-height: 52px;
    text-align: center;
    letter-spacing: 0.02em;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    flex-direction: column;
    @include mixins.from-tablet {
        flex-direction: row;
    }
}

.questions-text {
    font-size: 30px;
    font-weight: 600;
    margin-right: 0px;
    margin-bottom: 0px;
    @include mixins.from-tablet {
        flex-direction: row;
        margin-right: 20px;

    }

}
