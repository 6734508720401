@use "modules/common/utils/styles/_mixins";
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import "./static/styles/variables.scss";

* {
  font-family: "Raleway", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  object-fit: cover;
  color: $text-color;
}
a {
  text-decoration: none !important;
}
.App {
  background-color: #020c14;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.custom-container {
  margin-left: 15px;
  margin-right: 15px;

  @include mixins.from-tablet {
    margin-left: 50px;
    margin-right: 50px;
  }

  @include mixins.from-desktop {
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
  }

  @include mixins.from-desktopFull {
    max-width: unset;
    width: 1110px;
  }
}

h1,
p {
  color: $text-color;
}

.arrow-down {
  border: solid $text-color;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-bottom: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.sub-title {
  color: $text-color;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 15px;

  @include mixins.from-tablet {
    font-size: 26px;
    line-height: 31px;
  }
}

button {
  cursor: pointer;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nav-logo {
  max-width: 150px;
  margin-right: 5%;
}
.bm-item {
  outline: none !important;
}
.LeftBar,
.RightBar {
  display: inline-flex;
  align-items: center;
}

.social a {
  color: rgba(255, 255, 255, 0.7);
  margin-left: 15px;
}

.Nav,
.Social {
  display: inline-flex;
}

.change_region {
  color: $text-color;
  font-size: 16px;
  text-align: center;
  border-radius: 20px;
  margin-left: 55px;
  border: none;
  min-width: 150px;
  transition: all 0.1s linear;

  &:hover {
    cursor: pointer;
    background-color: $link-color-active;
  }

  &:active {
    background-color: $gray-bg;
    color: $link-color-hover;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $text-color;
}

.App-link {
  color: #61dafb;
}

/*burger*/

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: $text-color;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #d1d1d1;
}

.bm-burger-button {
  display: none;
  position: fixed;
  width: 30px;
  height: 25px;
  left: 50px;
  top: 50px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $text-color;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  transition: 0.5s;
}

.react-datepicker-wrapper {
  width: auto !important;
}

.paginationReact > li {
  color: $text-color;
  font-size: 18px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0.01em;
  border-radius: 50%;
  width: 39px;
  height: 39px;
  text-align: center;
  vertical-align: middle;

  @include mixins.from-tablet {
    width: 44px;
    height: 44px;
    font-size: 22px;
    line-height: 44px;
  }

  &:hover {
    color: $link-color-hover;
  }

  &:active {
    color: $link-color-active;
  }
}

.paginationReact > li > a {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: $text-color;
}

.paginationReact > li.previous,
.paginationReact > li.next {
  &:hover a {
    color: $link-color-active!important;
  }
}

.paginationReact {
  display: flex;
  justify-content: center;
  padding-left: 0;
}

.selected {
  background-color: $secondary;

  &:hover {
    color: $text-color!important;
  }
}

.disabled {
  display: none;
}

.callToActionFormModalContent {
  width: calc(100% - 32px);
  inset: 16px;
  max-height: 490px;
  margin: auto;
  background-color: #0B1B28;
  border-radius: 15px;
  border: 0;

  @include mixins.from-desktop {
    max-width: 730px;
    max-height: 510px;
  }
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.react-datepicker {
  background-color: $input-bg-color!important;
  color: $text-color!important;
  border: 0!important;
}

.react-datepicker__header  {
  color: $text-color!important;
  background-color: $input-bg-color!important;
  border-bottom: 0!important;
}

.react-datepicker-popper[data-placement^=bottom]
.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom]
.react-datepicker__triangle::after {
  border-bottom-color: $input-bg-color!important;
}

.react-datepicker__day--selected {
  background-color: transparent!important;
  color: $link-color-active!important;
  border: 1px solid $link-color-active;
  border-radius: 50%!important;
}

.react-datepicker__day:hover {
  background-color: $link-color-active!important;
  color: $text-color!important;
  border-radius: 50%!important;
}

.react-datepicker__day {
  color: $text-color!important;
}

.react-datepicker__current-month {
  color: $text-color!important;
  font-weight: 600!important;
  font-size: 16px!important;
}

.react-datepicker__day-name {
  color: $link-color!important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: $text-color!important;
}

.ReactModal__Overlay {
  background: rgb(12, 18, 22, 0.4)!important;
}

.ReactModal__Content {
  background-color: transparent!important;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  width: auto;
  padding: 0!important;
  // inset: 0!important;
  border: unset!important;

  @include mixins.from-desktop {
    max-height: 250px;
  }
}

.ReactModal__Content#region-detect {
  background-color: $menu-bg!important;
  max-height: 300px;
  border-radius: 40px!important;
}

.ReactModal__Content.callToActionFormModalContent {
  background-color: $menu-bg!important;
  padding: 40px!important;
}
